<template>
  <div class="terms contentWrapper">
    <h1>Terms of Sale</h1>

    <div class="content">
      <h3>1. Definitions</h3>
      <p><u>Buyer</u>: the party buying the Goods. <u>Seller</u>: NaturalPoint, Inc. d/b/a OptiTrack. <u>Goods</u>: the products, and related software, accessories, and/or services and support being offered by Seller. <u>Terms</u>:these Terms and Conditions of Sale. <u>Contract</u>: Seller’s Invoice, statement of work (“SOW”), and the documents referenced herein (including without limitation the software license agreements, limited warranties, service addendums, and service agreements).</p>

      <h3>2. Entire  Agreement</h3>
      <p>The Contract contains the entire agreement between the parties, superseding all prior and contemporaneous representations, understandings and agreements between the parties with respect to the Goods, including without limitation Buyer’s requests for proposals, requests for quotations, purchase order or payment terms and conditions, provided that a written agreement signed by both parties will supersede these Terms. In addition, no website or other click-through agreement will have any binding effect on Seller regardless of Seller’s clicking “ok”, “I accept” or other purported acceptance. This Contract may only be amended in writing, signed by both parties. The earliest of the following is Buyer’s consent to these Terms: (a) execution of Seller’s Credit Agreement, (b) issuance of a purchase order in response to a Seller quotation, or (c) receipt of or payment for Goods. If conflict arises between any of the documents comprising the Contract, the order listed in the definition will be their order of precedence. Supplier expressly rejects all different or additional terms provided on or with Buyer’s order or payment for Goods. Buyer understands and agrees that it is an express condition of Seller’s agreement to sell Goods to Buyer that Buyer accepts these Terms.</p>

      <h3>3. Purchase  Orders</h3>
      <p>Buyer may purchase Goods by issuing a written purchase order (“PO”) or submitting an online order to Seller (collectively, “Order”), or by entering into a SOW with Seller. Buyer will identify in its Order whether the sale is for Buyer’s use or for sale to a customer. Any sale to a customer must be for such customer’s end use, in the country of Buyer’s location noted above, and not for resale or further distribution to another party. All Orders must provide, at a minimum, the following information: date of issuance, the model number, quantity and price of each of the Goods, requested ship dates, complete bill-to and ship-to address, and the signature and contact information of the purchasing agent. Buyer’s Order is only accepted when Seller sends an invoice confirming the Order (“Invoice”) by regular or electronic mail. </p>

      <h3>4. Price; Payment</h3>
      <p>Seller's quoted price excludes all costs for delivery of the Goods to Buyer and all taxes and customs duties relating to the Goods. Payment will be made in the invoiced currency. Unless otherwise agreed by the parties in writing, Buyer will provide advance payment. If approved for credit, Buyer will pay Seller within 30 days of the date of Seller's invoice. Seller may invoice Buyer upon the earlier of shipment or notice of readiness to ship. Seller may make and invoice for partial shipments. For blanket orders or custom Goods, if Buyer does not take delivery of the entire order per the agreed upon schedule, Seller will invoice Buyer for the remaining balance due. If Buyer does not pay Seller on the agreed dates of payment, Buyer will pay interest of 1.5% of the unpaid balance monthly. If Seller refers a delinquent account to a collection agency, Seller will be entitled to recover the costs of the collection agency.</p>

      <h3>5. Taxes</h3>
      <p>Buyer will pay all sales, use, value-added, excise, gross receipts or other taxes, and any customs duties or other import/export fees, imposed on the Goods or on the sale, delivery, ownership or use of the Goods by Buyer, excluding any taxes based on Seller’s income or operations.</p>

      <h3>6. Acceptance</h3>
      <p>No acceptance conditions apply to Buyer’s purchase of Goods. Any defects in material or workmanship, or errors in shipment, will be provided for under the warranty in Section 10.</p>

      <h3>7. Title; Risk of Loss</h3>
      <p>Delivery will be Ex Works Factory (EXW Incoterms 2020). Loss, damage or destruction of Goods that occurs while the risk of loss is Buyer’s does not relieve Buyer of its obligation to pay Seller for the Goods. Transfer of title does not apply to software or other intellectual property that may be supplied with the Goods.</p>

      <h3>8. Delivery</h3>
      <p>Seller will use commercially reasonable efforts to meet the delivery dates specified in Seller’s Invoice or SOW, if applicable. Any delivery date provided by Seller is provided as an estimate only and Seller will not be liable for any delay in shipment or delivery. If Seller experiences supply and material shortages or other manufacturing delays, Seller may ship partial shipments of Buyer’s orders and invoice Buyer for those partial shipments.</p>

      <h3>9. Services</h3>
      <p>Seller may provide services, including without limitation design, fabrication, installation or maintenance services (“Services”), as specified in an Invoice or SOW. In the absence of an SOW, Buyer will pay for services performed and any actual and reasonable expenses incurred by Seller in performing the services. Seller personnel performing services are Seller’s employees for all purposes; Seller is responsible for all compensation, benefits and employment-related insurance and taxes. Services may be subcontracted to or performed by third parties on behalf of Seller. Buyer may request changes to a SOW by issuing a change order, which will only be valid if signed by both parties.</p>

      <h3>10. Limited Warranty</h3>
      <p><u>Goods:</u> Seller warrants that the Goods (excluding software) will conform to published specifications and be free from material defects in accordance with Seller’s standard warranty for such Goods found at Seller’s website. Any Software included with Goods is provided AS IS with no warranty from Seller, unless an express warranty is provided in the software license. Any third party Goods are provided with the manufacturer’s warranty only; Seller provides no additional warranty.</p>
      <p><u>Services:</u> For 90 days after completion of each particular service, Seller warrants to Buyer that such service has been performed in a professional manner consistent with industry standards. Buyer’s exclusive remedy for any breach of this warranty will be for Seller to re-perform the affected services at no additional cost to Buyer or, if the services cannot be re-performed, to refund the amount already paid for the affected services.</p>
      <p><u>Disclaimer:</u> SELLER DISCLAIMS AND BUYER WAIVES ALL OTHER WARRANTIES AND CONDITIONS, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND ALL WARRANTIES OR CONDITIONS BASED ON COURSE OF DEALING, COURSE OF PERFORMANCE OR TRADE USAGE.</p>

      <h3>11. Seller's Intellectual Property</h3>
      <p>Sale of Goods to Buyer does not convey a license, implied or otherwise, under any patent, copyright, trademark, trade secret or other proprietary right in which Seller has an interest, nor does it convey rights to any related data, including but not limited to Seller's product design, drawings, schematics, software, processes or tooling. Any designs, drawings, models or samples submitted by Seller, and all know-how and other intellectual property therein, will remain Seller’s property. Buyer agrees that it will not use any of Seller’s names or trademarks without Seller’s prior written permission.</p>

      <h3>12. Limitation of Liability</h3>
      <p>SELLER WILL NOT BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING IN ANY WAY TO THE CONTRACT OR ANY RELATIONSHIP ARISING OUT OF THE CONTRACT, OR ANY DEFECT IN OR FAILURE OF THE GOODS, OR ANY CLAIMS BASED UPON LOSS OF USE, LOST PROFITS OR REVENUE, INTEREST, LOST GOODWILL, ENVIRONMENTAL DAMAGE, INCREASED EXPENSES OF OPERATION, COST OF REPLACEMENT GOODS, OR CLAIMS BY OR ON BEHALF OF BUYER’S CUSTOMERS OR OTHER THIRD PARTIES, WHETHER OR NOT BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), STATUTE OR OTHERWISE. SELLER'S MAXIMUM LIABILITY UNDER THIS CONTRACT WILL NOT EXCEED THE PRICE PAID FOR THE GOODS UPON WHICH SUCH LIABILITY IS BASED. THE LIMITATIONS IN THIS CONTRACT APPLY TO THE FULLEST EXTENT PERMITTED BY LOCAL LAWS APPLICABLE TO BUYER. BUYER MAY HAVE RIGHTS THAT CANNOT BE WAIVED AND SOME OF THESE LIMITATIONS MAY NOT BE VALID IN SOME JURISDICTIONS.</p>

      <h3>13. Compliance with Laws; Export Laws</h3>
      <p>Buyer will comply with all applicable international, national, regional and local laws and regulations with regard to its marketing, sales, distribution and other activities under this Agreement, including the US Foreign Corrupt Practices Act and other applicable anti-corruption and anti-bribery laws. Goods are subject to US export laws and regulations, and may be subject to other export laws and regulations depending on where they are manufactured, assembled or sold. Buyer shall not export, re-export or transfer (directly or indirectly) Goods or technical data received from Seller (a) without strictly complying with all such laws and regulations, including obtaining all required licenses, authorizations, certifications and approvals, or (b) to any person, entity or country to which such transfer is prohibited, including pursuant to any sanction or embargo administered by the US, EU or other countries or organizations. Buyer will defend, indemnify and hold harmless Seller from and against any violation of such laws or regulations by Buyer, its customer or any of their agents, officers, directors or employees.</p>

      <h3>14. Governing Law</h3>
      <p>This Contract will be governed by the laws of the State of Oregon. The UN Convention on Contracts for the International Sale of Goods is expressly excluded from this Contract. Any disputes arising out of this Contract that cannot be informally resolved will be filed exclusively in the State of Oregon, except that Seller may bring an action to collect amounts owed or enforce any judgment entered against Buyer in any jurisdiction by suit on the judgment or in any other manner provided by law. Buyer irrevocably consents to the jurisdiction of the courts in the State of Oregon and waives any objection to such venue or jurisdiction. All claims by Buyer against Seller must be filed against Seller within one year from the date of the events that gave rise to the claim or be discharged and barred forever.</p>

      <h3>15. Termination</h3>
      <p>Buyer may not cancel its order, reduce quantities, revise specifications or extend scheduled delivery unless agreed to in writing signed by Seller. For standard product order, Buyer may be charged a restocking or cancellation fee of up to 25% of the purchase price for any change or cancellation. For orders designated by Seller as custom, or otherwise identified as custom with a unique part number, Buyer will be subject to additional fees of at least 50% if a request for reschedule or cancellation is granted. If Buyer requests to reschedule shipment for more than 30 days beyond the originally scheduled ship date, Seller may charge Buyer an additional storage and handling fee of up to 25%, if Buyer’s request is granted. Seller does not accept returns of custom Products. Seller may, at its option and without incurring liability to Buyer or prejudicing Seller’s rights to other remedies, either cancel or reschedule Buyer’s order if Buyer is in default of payment obligations or any other material term of this Contract, becomes insolvent, is the subject of bankruptcy proceedings, or ceases to do business in the ordinary course. Buyer’s obligations under the following sections will survive termination for any reason: Sections 4, 5, 7, 11, 12, 13, 14, 17 and 19.</p>

      <h3>16. Government Contracts</h3>
      <p>Government Contracts. Unless otherwise agreed to in writing signed by both parties, no term or condition required in any government contract or related subcontract will be part of this Contract or binding upon Seller, and Seller expressly rejects any government provisions included in or referred to by Buyer's request for quotation, purchase order or any other document.</p>

      <h3>17. Treatment of Waste</h3>
      <p>Buyer will take responsibility for taking back and/or recycling electrical and electronic equipment according to applicable local laws. Buyer exempts Seller from all legal obligations for taking back and/or recycling and from all possible claims of third parties in connection with these obligations and will fulfill these obligations itself at its own cost.</p>

      <h3>18. Changes</h3>
      <p>Seller may make process and design changes in Goods that do not materially affect form, fit and function without notice to or approval from Buyer. Seller also may furnish suitable substitutes for materials unobtainable, impractical or in shortened supply. Seller may discontinue the sale of any Product at any time and cancel any outstanding Orders for the discontinued Product.</p>

      <h3>19. Software License</h3>
      <p>If Seller provides software with the Products (“Software”), that Software is proprietary to Seller, a Third Party licensor, or Seller’s suppliers. The end user license provided with the Software or available at <a href='https://optitrack.com/about/legal/eula.html'>https://optitrack.com/about/legal/eula.html</a> as well as any applicable Third Party License governs the use of the Software. If no separate end user license is provided with the Software, Seller grants Buyer a nonexclusive, personal, nontransferable license to use the Software only in conjunction with the Products in a manner consistent with any applicable Third Party Licenses; the Software is provided on an AS IS basis with no warranties. Buyer understands and agrees that this license will in no event entitle Buyer to receive any source code for the Software. A portion of the software, however, may contain or consist of open source software, which Customer may use under the terms and conditions of the specific license under which the open-source software is distributed. Customer agrees that Customer will be bound by any and all end use license agreements and Third Party Licenses as a condition of purchase or use of the Software. All title to and intellectual property rights in Products, including any Software, remains with the applicable licensor(s).</p>
      <p>Buyer shall not, nor shall Buyer permit others to, decompile or reverse engineer the Software or otherwise copy, use or analyze it for any purpose other than the rights expressly granted in this section. Buyer shall have no other right to license, sublicense, lease, transfer or otherwise distribute the Software to any party. Buyer shall have no right to create derivative works based upon the Software.</p>

      <h3>20. Waiver, Severability</h3>
      <p>Failure by a party to insist on performance of any provision will not be a waiver of the rights or remedies available in the event of a subsequent default by the other party. The invalidity of any term of this Agreement will not affect the validity of the remaining terms and the parties agree to replace the invalid term with a valid term that most closely preserves the original economic positions of the parties.</p>

      <h3>21. Assignment</h3>
      <p>Seller may assign its rights or delegate its duties under this Contract without the prior written consent of Buyer. Buyer may not assign or otherwise transfer any of its rights, or delegate any of its obligations hereunder, directly or indirectly, including by operation of law, without the prior written consent of Seller.</p>

      <h3>22. Force Majeure</h3>
      <p>Seller will not be liable in any manner for failure to perform caused by war, riot, terrorism, fire, flood, epidemic, pandemic, earthquake or other natural or man-made disaster, or failure of any third-party hardware, software, electrical system or utility, delay in transportation, inability to obtain necessary labor or materials from usual sources, or other causes beyond the reasonable control of Seller.</p>

      <p class="revision">Rev: 07-2024</p>
    </div>
  </div>
  <!-- END terms -->
</template>
<script>
export default {
  name: "Terms"
};
</script>
<style lang="scss" scoped>
.terms {
  text-align: center;

  h1 {
    margin-bottom: 0px;
  }
  h1 ~ p {
    margin-top: 0px;
    margin-bottom: 45px;
  }

  h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 700;
  }

  h3 ~ p {
    margin-top: 0px;
  }

  .content {
    text-align: left;
    padding-bottom: 40px;
  }
}
</style>
